import slugify from "slugify";

export const createSlug = (str) =>
  slugify(str, {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  });

export const convertProductUrl = (str, vol) => {
  // Replace patterns of digit-dash-digit followed by "l" with digit.dot.digit
  // str = str.replace(/(\d)-(\d)l/g, "$1.$2l");

  // search for volume info in url, then convert riga-black-balsam-3x0-04 to riga-black-balsam-3x0.04
  /*
  const volumeWithoutL = vol.replace(" l", "").replace(",", ".");
  const volumeWihtoutLConvertedToDashes = volumeWithoutL.replace(".", "-");
  if (str.indexOf(volumeWihtoutLConvertedToDashes) > -1) {
    str = str.replace(volumeWihtoutLConvertedToDashes, volumeWithoutL);
  }
  */

  // Replace patterns where the letter "l" is followed by dash-digit, replacing the dash with a double dash
  //str = str.replace(/l-(\d)/g, "l--$1");

  return str;
};
