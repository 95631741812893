import { configureStore } from "@reduxjs/toolkit";
import pathReducer from "../features/path/pathSlice";
import { api } from "./services/products";

export const store = configureStore({
  reducer: {
    path: pathReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (gDM) => gDM().concat(api.middleware),
});
