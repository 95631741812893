import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { animationPresets, AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import { ArrowNext } from "../Navigation";
import { ReactComponent as Logo } from "../../assets/logo_white.svg";
import katalogas from "../../assets/gerimu_katalogas.svg";
import y2024 from "../../assets/2024.svg";
import { setCategory } from "../../features/path/pathSlice";
import "./page-cover.scss";

const PageCover = () => {
  const dispatch = useDispatch();
  const { setPreset } = useContext(AppContext);

  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  useEffect(() => {
    dispatch(setCategory(null));
  }, [dispatch]);

  return (
    <Link to="/katalogas" onMouseDown={onMouseDown}>
      <div className="page-cover">
        <div className="page-cover__wo">
          <div className="page-cover__top-row">
            <Logo className="page-cover__logo" />
            <img src={y2024} className="page-cover__2024" alt="2024" />
          </div>

          <img src={katalogas} className="page-cover__name" alt="katalogas" />
        </div>
      </div>
      <ArrowNext to="/katalogas" anchor={false} />
    </Link>
  );
};

export default PageCover;
