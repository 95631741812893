import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: null,
  brand: null,
  product: null,
};

export const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setBrand: (state, action) => {
      state.brand = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

export const { setCategory, setBrand, setProduct } = pathSlice.actions;

export const selectPath = (state) => state.path || {};

export default pathSlice.reducer;
