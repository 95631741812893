import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetCategoriesQuery } from "../../app/services/products";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { animationPresets } from "../../AppContext";
import { ArrowPrev, ArrowNext } from "../Navigation";
import { ReactComponent as Logo } from "../../assets/logo_white.svg";
import { ReactComponent as Title } from "../../assets/2022/turinys.svg";
import Loader from "../Loader";
import { setCategory } from "../../features/path/pathSlice";
import "./page-contents.scss";

const PageContents = () => {
  const dispatch = useDispatch();
  const { setPreset } = useContext(AppContext);
  const { data: categories, isLoading } = useGetCategoriesQuery();

  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  useEffect(() => {
    dispatch(setCategory(null));
  }, [dispatch]);

  return (
    <div className="page-contents">
      <div className="page-contents__inner">
        <Logo className="page-contents__logo" />
        <div className="page-contents__categories">
          <div className="page-contents__nav-wrapper">
            <Title className="page-contents__title" />
            {isLoading && <Loader />}
            {categories && (
              <ul>
                {categories.map((cat, i) => (
                  <li key={i}>
                    <Link
                      title={cat.title}
                      to={`/katalogas/${cat.url.toLowerCase()}`}
                      onMouseDown={onMouseDown}
                    >
                      {cat.title}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    title="UŽSAKYMO INFORMACIJA"
                    to={`/kontaktai`}
                    onMouseDown={onMouseDown}
                  >
                    UŽSAKYMO INFORMACIJA
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <ArrowPrev to="/" dark />
        {!isLoading && (
          <ArrowNext
            to={`/katalogas/${categories?.[0].url.toLowerCase()}`}
            dark
          />
        )}
      </div>
    </div>
  );
};

export default PageContents;
